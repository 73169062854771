import axios from 'axios'

export class Funcao {
  static async obtemTodos () {
    return axios.get('/funcao')
  }
  static async obtemUm (id) {
    return axios.get('/funcao/'+id)
  }
  static async cadastrar (payload) {
    return axios.post('/funcao', payload)
  }
  static async alterar (payload) {
    return axios.put('/funcao/'+payload.id, payload)
  }
  static async remover (id) {
    return axios.delete('/funcao/'+id)
  }
  
}
